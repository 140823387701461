export const validationConfig = {
  auPhoneNumber: {
    pattern:
      /(^((4|04)|(614))\d{8}$)|(^((0[2,3,7,8]{1})|((61[2,3,7,8]{1})))(\d{8})$)|(^(13[0]{2})([0-9]{6})$)|(^(18[0]{2})([0-9]{6})$)/,
  },
  nzPhoneNumber: {
    pattern:
      /(^((2|02)|(642))\d{4,9}$)|(^((0[3,4,6,7,9]{1})|(64[3,4,6,7,9]{1}))\d{4,8}$)|(^(0508)([0-9]{6,7})$)|(^(08)([0-9]{8,9})$)|(^(0900)([0-9]{5,6})$)/,
  },
};
