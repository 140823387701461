import { ErrorMessage } from 'formik';
import { FormFeedback } from 'reactstrap';
import Icon from '../Icon/Icon';

import './FormError.scss';

interface Props {
  name: string;
  errorId: string;
}

const FormError = ({ name, errorId }: Props) => {
  return (
    <ErrorMessage className="form-error" name={name}>
      {msg => (
        <FormFeedback className="form-error__feedback" id={errorId}>
          <Icon className="form-error__icon" name="icon-exclamation-mark" />
          {msg}
        </FormFeedback>
      )}
    </ErrorMessage>
  );
};

export default FormError;
