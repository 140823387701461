import { ReactNode } from 'react';
import './Title.scss';

interface Props {
  children: ReactNode;
}

const Title = ({ children }: Props) => {
  return (
    <div className="title">
      <h1>{children}</h1>
    </div>
  );
};

export default Title;
