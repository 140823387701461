import { useState, useEffect } from 'react';

import initialiseGoogleAnalytics from '../utils/ga';

export const useGaLoader = (gaId: string) => {
  const [isGaLoaded, setIsGaLoaded] = useState(false);
  const [googleAnalyticsError, setGoogleAnalyticsError] = useState(false);

  useEffect(() => {
    if (gaId) {
      initialiseGoogleAnalytics(gaId, setGoogleAnalyticsError, () => {
        setIsGaLoaded(true);
      });
    }
  }, [gaId]);

  useEffect(() => {
    if (googleAnalyticsError && gaId) {
      setIsGaLoaded(false);
      // console.error(`Error loading Google Analytics script. gaId - ${gaId}`);
    }
  }, [googleAnalyticsError, gaId]);

  return {
    isGaLoaded,
  };
};
