import { Field, FieldProps } from 'formik';
import { ReactNode } from 'react';
import { FormGroup, Label } from 'reactstrap';

import FormError from '../FormError/FormError';

export interface FormFieldChildProps {
  id: string;
  name: string;
  errorId?: string;
  invalid: boolean;
  error: React.ReactNode;
}

export type FormFieldChildOwnProps = FormFieldChildProps & FieldProps;

interface Props {
  required?: boolean;
  id?: string;
  name: string;
  label?: React.ReactNode;
  disableErrorMsg?: boolean;
  check?: boolean;
  validate?: (value: any) => string | Promise<void> | undefined;
  children: (childProps: FormFieldChildOwnProps) => React.ReactNode;
}

const FormField = ({ id, name, check, label, children, validate, disableErrorMsg }: Props) => {
  const fieldId = id ? id : name;
  const fieldName = name;
  const errorId = `${fieldId}_error`;

  return (
    <FormGroup check={check}>
      {label && <Label for={fieldId}>{label}</Label>}
      <Field name={name} validate={validate}>
        {({ field, form, meta }: FieldProps) => (
          <>
            {children({
              field,
              form,
              id: fieldId,
              name: fieldName,
              errorId,
              invalid: !!(form.touched[field.name] && form.errors[field.name]),
              error: form.errors[field.name] as ReactNode,
              meta,
            })}
            {!disableErrorMsg && <FormError name={name} errorId={errorId} />}
          </>
        )}
      </Field>
    </FormGroup>
  );
};

export default FormField;
