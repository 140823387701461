/* eslint-disable no-console */
class Log {
  public debug(message: string, ...args: object[]): void {
    this.emitLogMessage('debug', message, args);
  }
  public info(message: string, ...args: object[]): void {
    this.emitLogMessage('info', message, args);
  }

  public warn(message: string, ...args: object[]): void {
    this.emitLogMessage('info', message, args);
  }

  public error(message: string, ...args: object[]): void {
    this.emitLogMessage('info', message, args);
  }

  private emitLogMessage(
    messageType: 'debug' | 'warn' | 'error' | 'info',
    message: string,
    args: object[] = [],
  ) {
    if (args.length > 0) {
      console[messageType](message, args);
    } else {
      console[messageType](message);
    }
  }
}

const LoggerInstance = new Log();

export default LoggerInstance;
