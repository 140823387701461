import { Fragment } from 'react';
import { Input, Label } from 'reactstrap';
import classNames from 'classnames';
import FormField from '../FormField/FormField';
import './ColorList.scss';

interface Props {
  name: string;
}

const options = [
  'primary',
  'green',
  'blue',
  'crayola-navy-blue',
  'dark-cerulean',
  'dark-orange',
  'jazzberry-jam',
];

const ColorList = ({ name }: Props) => {
  return (
    <ul className="color-list">
      {options.map((value, index) => (
        <li className="color-list__item">
          <FormField name={name} disableErrorMsg={true} check={true}>
            {({ field, id, errorId, invalid }) => (
              <Fragment>
                <Input
                  {...field}
                  type="radio"
                  id={`${name}_option_${index}`}
                  placeholder="Email address"
                  value={value}
                  checked={field.value === value}
                  className="color-list__item-input"
                  aria-describedby={errorId}
                  invalid={invalid}
                />
                <Label
                  className={classNames(
                    'color-list__item-label',
                    `color-list__item-label--${value}`,
                  )}
                  htmlFor={`${name}_option_${index}`}
                >
                  <div
                    className={classNames(
                      'color-list__item-circle',
                      `color-list__item-circle--${value}`,
                    )}
                  ></div>
                </Label>
              </Fragment>
            )}
          </FormField>
        </li>
      ))}
    </ul>
  );
};

export default ColorList;
