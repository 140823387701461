import { BrowserRouter, Route, Routes } from 'react-router-dom';

import BrokerConfig from './BrokerConfig/BrokerConfig';
import PartnerPortalConfig from './PartnerPortalConfig/PartnerPortalConfig';
import Main from './Main';

const App = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Routes>
      <Route path="/brokerConfig" Component={BrokerConfig} />
      <Route path="/partnerPortal" Component={PartnerPortalConfig} />
      <Route path="*" Component={Main} />
    </Routes>
  </BrowserRouter>
);

export default App;
