import { useContext, useEffect, useRef, useState } from 'react';
import { Button, CustomInput, Input } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import FormField from '../../../components/FormField/FormField';
import Title from '../../../components/Title/Title';
import Icon from '../../../components/Icon/Icon';
import { AppContext } from '../../../Main';
import { consentLink, privacyPolicyLink } from '../../../constants';
import { useGaLoader } from '../../../hooks/useGaLoader';
import OrchestratorApi from '../../../services/OrchestratorApi';
import { validationConfig } from '../../../utils/validation';

import { useNavigate } from 'react-router-dom';
import './ShortForm.scss';

interface Values {
  fullName: string;
  phoneNumber: string;
  emailaddress: string; // from old app
  businessName: string;
  revenue: string;
  state: string;
  timeInBusiness: string;
  loanSize: string;
  consent: boolean;
}

const initialValues: Values = {
  fullName: '',
  phoneNumber: '',
  emailaddress: '',
  businessName: '',
  revenue: '',
  state: '',
  timeInBusiness: '',
  loanSize: '',
  consent: false,
};

const auValidationSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(3, 'Full name must be at least 3 characters')
    .required('Your full name is required'),
  phoneNumber: Yup.string()
    .required('Your phone number is required')
    .test('type', 'Your phone number is invalid', value =>
      value ? Boolean(value.match(validationConfig.auPhoneNumber.pattern)) : false,
    ),
  emailaddress: Yup.string()
    .email('Your email address is invalid')
    .required('Your email address is required'),
  businessName: Yup.string().required('Your business name is required'),
  revenue: Yup.string().required('Average monthly sales is required'),
  timeInBusiness: Yup.string().required('Your time in business is required'),
  loanSize: Yup.string().required('Your loan amount is required'),
  consent: Yup.boolean().test(value => value === true),
});

const nzValidationSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(3, 'Full name must be at least 3 characters')
    .required('Your full name is required'),
  phoneNumber: Yup.string()
    .required('Your phone number is required')
    .test('type', 'Your phone number is invalid', value =>
      value ? Boolean(value.match(validationConfig.nzPhoneNumber.pattern)) : false,
    ),
  emailaddress: Yup.string()
    .email('Your email address is invalid')
    .required('Your email address is required'),
  businessName: Yup.string().required('Your business name is required'),
  revenue: Yup.string().required('Average monthly sales is required'),
  timeInBusiness: Yup.string().required('Your time in business is required'),
  loanSize: Yup.string().required('Your loan amount is required'),
  consent: Yup.boolean().test(value => value === true),
});

const validateState = (value: string) => {
  if (!value) {
    return 'Your state is required';
  }

  return undefined;
};

const ShortForm = () => {
  const navigate = useNavigate();
  const { config, correlationId } = useContext(AppContext);
  const hideInfoPanel = config?.layout === 'hideInfoPanel';
  const isCountryNZ = config?.country === 'nz';
  const shortFormRef = useRef<any>(null);
  const [submitting, setSubmitting] = useState(false);
  useGaLoader(config.googleAnalytics || '');

  useEffect(() => {
    setTimeout(() => {
      broadcastDimensions();
    }, 10);
  }, []);

  useEffect(() => {
    function handleResize() {
      broadcastDimensions();
    }

    window.addEventListener('resize', handleResize);
  }, []);

  const broadcastDimensions = () => {
    if (shortFormRef.current) {
      const data = {
        dimensions: {
          width: shortFormRef.current.clientWidth,
          height: shortFormRef.current.clientHeight + 30,
        },
      };
      window.parent.postMessage(JSON.stringify(data), '*');
    }
  };

  const handleSubmit = (values: Values, helpers: FormikHelpers<Values>) => {
    setSubmitting(true);
    const nameArr = values.fullName.split(' ');
    const name = {
      firstName: nameArr[0],
      lastName: 'na',
    };

    if (nameArr.length > 1) {
      name.lastName = nameArr.splice(1).join(' ');
    }

    // TODO: Submit to API
    const request = {
      firstName: name.firstName,
      lastName: name.lastName,
      phoneNumber: values.phoneNumber,
      emailAddress: values.emailaddress,
      tradingName: values.businessName,
      totalMonthlySales: values.revenue,
      partnerId: config.brokerId,
      partnerContactId: config.partnerContactId,
      businessState: values.state,
      timeInBusiness: values.timeInBusiness,
      loanAmount: String(values.loanSize),
      termsAndConditions: values.consent,
      productName: isCountryNZ ? 'NZ Small Business Loans(Unsecured)' : '',
      source: 'Other',
      otherSource: 'Short Form iFrame',
    };

    if (!submitting) {
      OrchestratorApi.put({ correlationId, ...request })
        .then(() => {
          setSubmitting(false);

          if (config.successUrl) {
            window.parent.location.href = config.successUrl;
          } else {
            navigate('/success');
          }
        })
        .catch(error => {
          // console.error(error);
        });
    }
  };

  const handleDimensionChange = () => {
    setTimeout(() => {
      broadcastDimensions();
    }, 0);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={isCountryNZ ? nzValidationSchema : auValidationSchema}
    >
      <div
        ref={shortFormRef}
        className={`short-form-container theme--${config.theme || 'primary'}`}
      >
        <Title>{config.showTitle !== 'no' ? config.title : ''}</Title>
        <Form className="short-form">
          <div className="short-form__row">
            {!hideInfoPanel && (
              <div
                className={classNames('short-form__panel', {
                  'hide-xs': config.infoPanelMobile === 'hide',
                })}
              >
                <h2 className="short-form__subtitle">Apply in minutes</h2>
                <p>
                  Being a small business owner can be tough but accessing funds to help you reach
                  your business goals doesn't have to be. We'll go out of our way to get to know
                  your business so we can help you find a funding solution.
                </p>
                <p>Free to apply, no obligation.</p>
                <p>
                  {isCountryNZ ? (
                    <small>
                      * Eligibility and approval is subject to standard credit assessment and not
                      all amounts, term lengths or rates will be available to all applicants. Fees,
                      terms and conditions apply. FSP663891.
                    </small>
                  ) : (
                    <small>
                      * Eligibility and approval is subject to standard credit assessment and not
                      all amounts, term lengths or rates will be available to all applicants. Fees,
                      terms and conditions apply. Australian credit licence 454782.
                    </small>
                  )}
                </p>
              </div>
            )}
            <div className="short-form__panel">
              <FormField name="fullName" label="Full name">
                {({ field, errorId, invalid, id }) => (
                  <Input
                    {...field}
                    type="text"
                    id={id}
                    name={id}
                    placeholder="Full name"
                    aria-describedby={errorId}
                    invalid={invalid}
                  />
                )}
              </FormField>
              <FormField name="phoneNumber" label="Preferred phone number">
                {({ field, errorId, invalid, id }) => (
                  <Input
                    {...field}
                    type="text"
                    id={id}
                    name={id}
                    placeholder="Preferred phone number"
                    aria-describedby={errorId}
                    invalid={invalid}
                  />
                )}
              </FormField>
              <FormField name="emailaddress" label="Email address">
                {({ field, errorId, invalid, id }) => (
                  <Input
                    {...field}
                    type="text"
                    id={id}
                    name={id}
                    placeholder="Email address"
                    aria-describedby={errorId}
                    invalid={invalid}
                  />
                )}
              </FormField>
              <FormField name="businessName" label="Business trading name">
                {({ field, errorId, invalid, id }) => (
                  <Input
                    {...field}
                    type="text"
                    id={id}
                    name={id}
                    placeholder="Business trading name"
                    aria-describedby={errorId}
                    invalid={invalid}
                  />
                )}
              </FormField>
              <FormField name="revenue" label="Average monthly sales">
                {({ field, errorId, invalid, id }) => (
                  <Input
                    {...field}
                    type="text"
                    id={id}
                    name={id}
                    placeholder="Average monthly sales"
                    aria-describedby={errorId}
                    invalid={invalid}
                  />
                )}
              </FormField>
            </div>
            <div className="short-form__panel">
              {!isCountryNZ && (
                <FormField
                  name="state"
                  label="Where do you live?"
                  validate={isCountryNZ ? undefined : validateState}
                >
                  {({ field, errorId, invalid, id }) => (
                    <CustomInput
                      {...field}
                      type="select"
                      id={id}
                      name={id}
                      placeholder="Please select"
                      aria-describedby={errorId}
                      invalid={invalid}
                    >
                      <option value="">Please select</option>
                      <option value="NSW">New South Wales</option>
                      <option value="QLD">Queensland</option>
                      <option value="VIC">Victoria</option>
                      <option value="SA">South Australia</option>
                      <option value="NT">Northern Territory</option>
                      <option value="WA">Western Australia</option>
                      <option value="ACT">Australian Capital Terrirory</option>
                      <option value="TAS">Tasmania</option>
                    </CustomInput>
                  )}
                </FormField>
              )}
              <FormField name="timeInBusiness" label="How long have you been in business?">
                {({ field, errorId, invalid, id }) => (
                  <CustomInput
                    {...field}
                    type="select"
                    id={id}
                    name={id}
                    placeholder="Please select"
                    aria-describedby={errorId}
                    invalid={invalid}
                  >
                    <option value="">Please select</option>
                    <option value="Less than 6 months">Less than 6 months</option>
                    <option value="6 - 12 months">6 - 12 months</option>
                    <option value="Over 1 year">More than 12 months</option>
                  </CustomInput>
                )}
              </FormField>
              <FormField name="loanSize" label="How much do you need?">
                {({ field, errorId, invalid, id }) => (
                  <Input
                    {...field}
                    type="number"
                    id={id}
                    name={id}
                    placeholder="Enter an amount"
                    aria-describedby={errorId}
                    invalid={invalid}
                  />
                )}
              </FormField>
              <FormField name="consent" disableErrorMsg={true}>
                {({ field, invalid, id }) => (
                  <CustomInput
                    {...field}
                    className="short-form__consent-input"
                    type="checkbox"
                    invalid={invalid}
                    id={id}
                    label={
                      <>
                        I accept and consent to the{' '}
                        <a
                          target="_parent"
                          className="short-form__consent-link"
                          href={consentLink[config.country] || consentLink.au}
                        >
                          Personal and Electronic Consents
                        </a>{' '}
                        and{' '}
                        <a
                          target="_parent"
                          className="short-form__consent-link"
                          href={privacyPolicyLink[config.country] || privacyPolicyLink.au}
                        >
                          Privacy Policy
                        </a>
                        .
                      </>
                    }
                  />
                )}
              </FormField>
              <hr />
              <div className="short-form__cta">
                <div className="short-form__encryption">
                  <Icon className="short-form__encryption-icon" name="icon-shield" />
                  <div className="short-form__encryption-text">
                    <small>We use 256-bit encryption to protect your information</small>
                  </div>
                </div>
                <div className="short-form__submit-container">
                  <Button
                    onClick={handleDimensionChange}
                    className="short-form__submit"
                    color="primary"
                    type="submit"
                    size="sm"
                    disabled={submitting}
                  >
                    {submitting ? 'One moment...' : 'Get started'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Formik>
  );
};

export default ShortForm;
