import { AxiosPromise } from 'axios';

import environments from '../environments';
import { InitRequest, InitResponse, SaveFieldRequest } from '../models/Orchestrator';
import HttpClient from './HttpClient';

class OrchestratorApi {
  private version = 'v1';
  private url = `${environments.orchestratorServer.baseUri}/${this.version}/api`;
  private xsrfToken = 'aasdas';

  public init = (request: InitRequest | {}): AxiosPromise<InitResponse> =>
    HttpClient.post<InitResponse>(`${this.url}/lead`, {
      data: request,
      headers: this.getHeader(),
      withCredentials: true,
      crossDomain: true,
    });

  public put = (request: SaveFieldRequest) =>
    HttpClient.put(`${this.url}/lead`, {
      data: request,
      headers: this.getHeader(),
      withCredentials: true,
      crossDomain: true,
      xsrfCookieName: 'X-XSRF-TOKEN',
      xsrfHeaderName: 'X-XSRF-TOKEN',
    });

  private getHeader = (correlationId?: string) => {
    let header = {
      'X-Correlation-ID': correlationId || '',
      'Content-Type': 'application/json',
    };

    // IE <=11 only.
    // When user login out and login in with different account, they will still see old data which is cached by IE
    // for reference:
    // https://cherniavskii.com/internet-explorer-requests-caching/
    // So we add below header to avoid cache
    if (window.navigator.userAgent.match(/(MSIE|Trident)/)) {
      header = Object.assign(header, { Pragma: 'no-cache' });
    }
    return header;
  };
}

const orchestratorApi = new OrchestratorApi();
export default orchestratorApi;
