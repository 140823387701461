import { useState, useEffect } from 'react';

import qs from 'query-string';
import { Location } from 'react-router-dom';

import { InitResponse, SessionsParameters } from '../models/Orchestrator';
import OrchestratorApi from '../services/OrchestratorApi';

export const useOrchestratorLoader = (location: Location) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<InitResponse>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    async function init() {
      const queryString = qs.parse(location.search);
      const correlationId = (queryString?.id as string) || (queryString?.correlationId as string);
      const countryCode = queryString?.country || 'au';
      const sid = queryString?.sid as string;
      const sessionsParameters: SessionsParameters = {
        utmCampaign: (queryString.utm_campaign as string) || null,
        utmContent: (queryString.utm_content as string) || null,
        utmMedium: (queryString.utm_medium as string) || null,
        utmSource: (queryString.utm_source as string) || null,
        utmTerm: (queryString.utm_term as string) || null,
        googleClickId: (queryString.gclid as string) || null,
        googleAnalyticsClientId: (queryString.gaClientId as string) || null,
        digitalLeadSource: (queryString.dls as string) || null,
        digitalLeadVariant: (queryString.dlv as string) || null,
        externalRequestVersion: (queryString.externalRequestVersion as string) || null,
        partnerId: (queryString.partnerId as string) || null,
        partnerContactId: (queryString.partnerContactId as string) || null,
      };

      try {
        const res = await OrchestratorApi.init({
          sid,
          correlationId,
          countryCode,
          sessionsParameters,
        });
        setData(res.data);
      } catch (error: any) {
        if (error?.status === 404) {
          setError('expired');
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    }
    if (location.pathname === '/') {
      init();
    }
  }, [location.pathname, location.search]);

  return { data, loading, error };
};
