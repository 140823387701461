import { Formik, Form } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Label, Container, Input } from 'reactstrap';
import qs from 'query-string';
import ColorList from '../components/ColorList/ColorList';
import FormField from '../components/FormField/FormField';
import RadioButtons from '../components/RadioButtons/RadioButtons';
import { ShortformConfig } from '../models/Config';

import './BrokerConfig.scss';

interface BrokerConfigItemProps {
  label: string;
  children: React.ReactNode;
}

interface BrokerConfigCodeItemProps {
  title?: string;
  description: string;
  children: React.ReactNode;
}

const initialValues: ShortformConfig = {
  theme: '',
  layout: '',
  country: 'au',
  bg: '',
  brokerId: '',
  partnerContactId: '',
};

const BrokerConfigItem = ({ label, children }: BrokerConfigItemProps) => {
  return (
    <div className="broker-config__row">
      <div className="broker-config__col">
        <Label className="broker-config__label">{label}</Label>
      </div>
      <div className="broker-config__col">{children}</div>
    </div>
  );
};

const BrokerConfigCodeItem = ({ title, description, children }: BrokerConfigCodeItemProps) => {
  return (
    <div className="broker-config__code">
      {title && <h4 className="broker-config__code-title">{title}</h4>}
      <p className="broker-config__description">{description}</p>
      <pre>{children}</pre>
    </div>
  );
};

const BrokerConfig = () => {
  const frameAppRef = useRef<any>(null);
  const iframeRef = useRef<any>(null);
  const [frameLoaded, setFrameLoaded] = useState(false);

  useEffect(() => {
    if (frameLoaded) {
      broadcastDimensions();
    }
  }, [frameLoaded]);

  useEffect(() => {
    function handleResize() {
      broadcastDimensions();
    }

    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
      if (iframeRef.current && data?.dimensions) {
        iframeRef.current.style.height = data.dimensions.height + 'px';
      }
    }

    window.addEventListener('message', handleMessage);
  }, []);

  const broadcastDimensions = () => {
    if (iframeRef.current) {
      const iframeBody = iframeRef.current.contentWindow.document.body;
      const height = Math.max(iframeBody.scrollHeight, iframeBody.offsetHeight);

      const data = {
        dimensions: {
          width: iframeRef.current.clientWidth,
          height: height + 30,
        },
      };

      window.postMessage(JSON.stringify(data), '*');
    }
  };

  const getIframeSrc = (values: ShortformConfig) => {
    return `${window.location.origin}?${qs.stringify(values, { skipEmptyString: true })}`;
  };

  const getIframeAttr = (values: ShortformConfig) => {
    let attributes = '';
    if (values.theme && values.theme !== 'primary') {
      attributes += ` theme="${values.theme}"`;
    }
    if (values.layout && values.layout === 'hideInfoPanel') {
      attributes += ` layout="${values.layout}"`;
    }
    if (values.bg && values.bg !== 'white') {
      attributes += ` bg="${values.bg}"`;
    }
    if (values.country) {
      attributes += ` country="${values.country}"`;
    }
    if (values.partnerContactId) {
      attributes += ` partnerContactId="${values.partnerContactId}"`;
    }
    if (values.successUrl) {
      attributes += ` successUrl="${values.successUrl}"`;
    }
    if (values.googleAnalytics) {
      attributes += ` googleAnalytics="${values.googleAnalytics}"`;
    }
    if (values.infoPanelMobile) {
      attributes += ` infoPanelMobile="${values.infoPanelMobile}"`;
    }
    if (values.showTitle) {
      attributes += ` showTitle="${values.showTitle}"`;
    }

    return attributes;
  };

  return (
    <div ref={frameAppRef} id="brokerConfig" className="broker-config theme--primary">
      <Container>
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          {({ values }) => (
            <>
              <Form className="broker-config__form">
                <h1 className="text-center">Broker IFrame Configuration</h1>
                <article className="broker-config__box">
                  <h3 className="broker-config__title">Configure your IFrame</h3>
                  <BrokerConfigItem label="Select a theme:">
                    <ColorList name="theme" />
                  </BrokerConfigItem>
                  <BrokerConfigItem label="Show title">
                    <RadioButtons
                      className="broker-config__radio"
                      name="showTitle"
                      options={[
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ]}
                    />
                  </BrokerConfigItem>
                  <BrokerConfigItem label="Select a layout:">
                    <RadioButtons
                      className="broker-config__radio"
                      name="layout"
                      options={[
                        {
                          label: 'Show info panel',
                          value: 'showInfoPanel',
                        },
                        {
                          label: 'No info panel',
                          value: 'hideInfoPanel',
                        },
                      ]}
                    />
                  </BrokerConfigItem>
                  {values.layout === 'showInfoPanel' && (
                    <BrokerConfigItem label="Show info panel on mobile:">
                      <RadioButtons
                        className="broker-config__radio"
                        name="infoPanelMobile"
                        options={[
                          {
                            label: 'Show on mobile',
                            value: 'show',
                          },
                          {
                            label: 'Hide on mobile',
                            value: 'hide',
                          },
                        ]}
                      />
                    </BrokerConfigItem>
                  )}
                  <BrokerConfigItem label="Select a background:">
                    <RadioButtons
                      className="broker-config__radio"
                      name="bg"
                      options={[
                        {
                          label: 'Transparent',
                          value: 'transparent',
                        },
                        {
                          label: 'White',
                          value: 'white',
                        },
                      ]}
                    />
                  </BrokerConfigItem>
                  <BrokerConfigItem label="Select country:">
                    <RadioButtons
                      className="broker-config__radio"
                      name="country"
                      options={[
                        {
                          label: 'Australia',
                          value: 'au',
                        },
                        {
                          label: 'New Zealand',
                          value: 'nz',
                        },
                      ]}
                    />
                  </BrokerConfigItem>
                  <BrokerConfigItem label="Broker ID:">
                    <FormField name="brokerId">
                      {({ field, errorId, id, invalid }) => (
                        <Input
                          {...field}
                          type="text"
                          id={id}
                          name={id}
                          placeholder="Your Broker ID received from Prospa"
                          aria-describedby={errorId}
                          invalid={invalid}
                        />
                      )}
                    </FormField>
                  </BrokerConfigItem>
                  <BrokerConfigItem label="Partner Contact Id">
                    <FormField name="partnerContactId">
                      {({ field, errorId, id, invalid }) => (
                        <Input
                          {...field}
                          type="text"
                          id={id}
                          name={id}
                          placeholder="Your Partner Contact ID"
                          aria-describedby={errorId}
                          invalid={invalid}
                        />
                      )}
                    </FormField>
                  </BrokerConfigItem>
                  <BrokerConfigItem label="Success Page (optional)">
                    <FormField name="successUrl">
                      {({ field, errorId, id, invalid }) => (
                        <Input
                          {...field}
                          type="url"
                          id={id}
                          name={id}
                          placeholder="Success page to be redirected"
                          aria-describedby={errorId}
                          invalid={invalid}
                        />
                      )}
                    </FormField>
                  </BrokerConfigItem>
                  <BrokerConfigItem label="Enter your Google Analytics id (optional)">
                    <FormField name="googleAnalytics">
                      {({ field, errorId, id, invalid }) => (
                        <Input
                          {...field}
                          type="text"
                          id={id}
                          name={id}
                          placeholder="Google Analytics ID"
                          aria-describedby={errorId}
                          invalid={invalid}
                        />
                      )}
                    </FormField>
                  </BrokerConfigItem>
                  <BrokerConfigCodeItem
                    title="Frame loader Javascript"
                    description={`Add this script just above the ${'</body>'} tag on the brokers web page.`}
                  >
                    {`<script>(function() {var t = document.createElement("script");t.type = "text/javascript";t.id = "prospaFrameLoader";t.async = !0;t.src = "${window.location.origin}/frameLoader.min.js" +"?_d=" + (new Date).getTime();var n = document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t, n);})();</script>`}
                  </BrokerConfigCodeItem>
                  <BrokerConfigCodeItem
                    title="IFrame Placeholder tag"
                    description="Place this tag on the brokers web page where you want the IFrame to appear."
                  >
                    {`<prospa-form brokerId="${values.brokerId}"${getIframeAttr(
                      values,
                    )}></prospa-form>`}
                  </BrokerConfigCodeItem>
                  <BrokerConfigCodeItem description="If you don't have access to the header and footer (i.e. Wix), follow these instructions:">
                    {`<div style="width:1078px; height:800px; overflow: hidden"> <iframe src="${getIframeSrc(
                      values,
                    )}" scrolling="no" style="width: 100%; height: 100%; overflow: hidden; border:none;"></iframe></div>`}
                  </BrokerConfigCodeItem>
                </article>
              </Form>
              <h2 className="broker-config__preview-title">Preview</h2>
              <section className="broker-config__preview">
                <iframe
                  ref={iframeRef}
                  onLoad={() => setFrameLoaded(true)}
                  className="broker-config__preview-frame"
                  title="previewFrame"
                  id="brokerConfigFrame"
                  src={getIframeSrc(values)}
                  scrolling="false"
                  width="100%"
                />
              </section>
            </>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default BrokerConfig;
