import classNames from 'classnames';
import Sprite from '../../../assets/sprite-icons.svg';

import './Icon.scss';

interface Props {
  name:
    | 'icon-exclamation-mark'
    | 'icon-calendar'
    | 'icon-dollar-o'
    | 'icon-suitcase'
    | 'icon-shield';
  className?: string;
}

const Icon = ({ name, className }: Props) => {
  return (
    <svg className={classNames('icon', className)} role="img">
      <use xlinkHref={`${Sprite}#${name}`}></use>
    </svg>
  );
};

export default Icon;
