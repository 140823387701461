export const privacyPolicyLink = {
  au: 'https://www.prospa.com/privacy-policy',
  nz: 'https://www.prospa.co.nz/privacy-policy',
};

export const consentLink = {
  au: 'https://www.prospa.com/consent-information',
  nz: 'https://www.prospa.co.nz/consent-information',
};

export const phoneNumbers = {
  au: '1300 964 808',
  nz: '0800 005 834',
};
