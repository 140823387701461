import { dev } from './dev';
import { mock } from './mock';
import { prod } from './prod';
import { staging } from './staging';

const getConfig = () => {
  if (process.env.REACT_APP_MOCK_ENABLED) {
    return mock;
  }

  switch (window['__env'].environment) {
    case 'staging':
      return staging;
    case 'prod':
      return prod;
    default:
      return dev;
  }
};

const config = { ...getConfig() };
export default config;
