import './Success.scss';

const Success = () => {
  return (
    <div className="success">
      <h1>Thanks for your inquiry.</h1>
      <p>One of our friendly team will be in contact shortly.</p>
    </div>
  );
};

export default Success;
