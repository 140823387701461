import { createContext } from 'react';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import qs from 'query-string';
import { useOrchestratorLoader } from './hooks/useOrchestratorLoader';
import { AppContextType } from './models/AppContext';
import { ShortformConfig } from './models/Config';
import { routeConfig } from './routes';

const INITIAL_CONTEXT = {
  correlationId: '',
  config: {
    theme: 'primary',
    layout: '',
    country: 'au' as const,
    bg: '',
    brokerId: '',
    infoPanelMobile: '',
    partnerContactId: '',
    successUrl: '',
    googleAnalytics: '',
    showTitle: 'yes',
    title: '',
  },
};

export const AppContext = createContext<AppContextType>(INITIAL_CONTEXT);

const defaultTitle = 'The smarter way to fund your business!';

const Main = () => {
  const location = useLocation();
  const { data, loading, error } = useOrchestratorLoader(location);
  const version = data?.version;
  const correlationId = data?.correlationId || '';
  const routes = routeConfig[(version as keyof typeof routeConfig) || 'v1-iframe'];

  const queryString = qs.parse(location.search);
  const config: ShortformConfig = {
    theme: queryString?.theme as string,
    layout: queryString?.layout as string,
    infoPanelMobile: queryString?.infoPanelMobile as string,
    country: queryString?.country as 'au' | 'nz',
    bg: queryString?.bg as string,
    brokerId: queryString?.brokerId as string,
    partnerContactId: queryString?.partnerContactId as string,
    successUrl: queryString?.successUrl as string,
    googleAnalytics: queryString?.googleAnalytics as string,
    showTitle: queryString?.showTitle as string,
    title: defaultTitle,
  };

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <AppContext.Provider value={{ config, correlationId }}>
      <Routes>
        {routes?.map(({ path, Component }) => (
          <Route index={!path} path={path} Component={Component} key={path} />
        ))}
        <Route path="*" element={<Navigate to={`/${window.location.search}`} />} />
      </Routes>
    </AppContext.Provider>
  );
};

export default Main;
