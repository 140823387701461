const GTM_SCRIPT_ID = 'gaScript';

const loadGaScript = (
  id: string,
  setError: (error: boolean) => void,
  onLoadCallback: () => void = () => {},
) => {
  const script = document.createElement('script');
  script.text =
    "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');" +
    "ga('create', '" +
    id +
    "', 'auto');" +
    "ga('send', 'pageview');";
  script.async = true;
  script.onerror = () => setError(true);
  script.id = GTM_SCRIPT_ID;
  script.onload = () => onLoadCallback();
  document.body.appendChild(script);
};

const initialiseGoogleAnalytics = (
  gaId: string,
  setError: (error: boolean) => void,
  onLoadCallback?: () => void,
) => {
  const existingGtmScript = document.getElementById(GTM_SCRIPT_ID);
  if (!existingGtmScript) {
    loadGaScript(gaId, setError, onLoadCallback);
  }
};

export default initialiseGoogleAnalytics;
