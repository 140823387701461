/**
 * This is a route config file
 * This will contain all the specific routes and
 * it's specific components for particular version
 * provided by BE
 */

import ShortForm from './pages/shared/ShortForm/ShortForm';
import Success from './pages/shared/Success/Success';

export const routeConfig = {
  'v1-iframe': [
    { path: '', Component: ShortForm },
    { path: 'success', Component: Success },
  ],
};
