import { Field, FieldProps } from 'formik';
import { Fragment } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { RadioButtonOptions } from '../../models/Form';
import FormError from '../FormError/FormError';

import './RadioButtons.scss';

interface Props {
  id?: string;
  label?: string;
  name: string;
  options: RadioButtonOptions[];
  check?: boolean;
  className?: string;
}

const RadioButtons = ({ id, label, name, options, check, className, ...rest }: Props) => {
  const fieldId = id ? id : name;
  const errorId = `${fieldId}_error`;
  return (
    <FormGroup className={className} check={check}>
      {label && <Label>{label}</Label>}
      <Field name={name} {...rest}>
        {({ field, form }: FieldProps) => (
          <div className="radio-buttons">
            {options.map(option => (
              <Fragment key={option.value}>
                <Input
                  {...field}
                  type="radio"
                  id={option.value}
                  value={option.value}
                  checked={field.value === option.value}
                  className="radio-buttons__input"
                  invalid={!!(form.touched[field.name] && form.errors[field.name])}
                />
                <Label className="radio-buttons__label" htmlFor={option.value}>
                  {option.label}
                </Label>
              </Fragment>
            ))}
          </div>
        )}
      </Field>
      <FormError name={name} errorId={errorId} />
    </FormGroup>
  );
};

export default RadioButtons;
